<template>
    <v-card>
        <v-card-title>
            <s-toolbar color="primary" dark label="Listado de Guias"  ></s-toolbar>
        </v-card-title>
        <v-card-text>
            <div class="row" justify="center">
                <v-col cols="auto">
                    <s-date label="Fecha Inicio"
                        v-model="DateBegin"
                        @input="searchitemsSend($event)"
                        >
                    </s-date>
                </v-col>
                <v-col cols="auto">
                    <s-date label="Fecha Fin"
                        v-model="DateEnd"
                        @input="searchitemsSend($event)"
                        >
                    </s-date>
                </v-col>
                <v-col cols="auto">
                    <s-select-client
                        clearable
                        label="Cliente(es):"
                        v-model="filter.CumID"
                        ref="txtCumID"
                        full 
                    >
                    </s-select-client>  
                </v-col>
                
                <v-col cols="auto">
                    <s-select-definition
                        clearable
                        label="Tipo Empaque"
                        v-model="filter.TypePacking"
                        :def="1435"
                        
                    ></s-select-definition>
                </v-col>
                <v-col cols="auto">                              
                    <s-select-definition
                        clearable
                        label="Tipo corte"
                        :def="1429"
                        v-model="filter.TypeCut"
                        
                    ></s-select-definition>  
                </v-col>
            </div>
            <v-row>
                <v-col cols="12">
                    <v-data-table dense 
                        :headers="headersGuide"
                        :items="ItemsGuide"
                        :items-per-page="-1" 
                        disable-sort 

                        item-key="PppID" >
                        <template  v-slot:item.Update="{ item }">                            
                            <v-btn	                                    	
                                fab
                                color="green"
                                x-small                                        
                                depressed
                                @click="ShowViewdialogGruide(item)"
                            >
                                <v-icon class="mdi-24px mdi-light">mdi-grease-pencil</v-icon>
                            </v-btn> 
                            
                        </template>
                        <template v-slot:item.Pdf="{ item }">
                            <v-btn                                   
                                fab
                                color="green"
                                x-small                                        
                                depressed
                                @click="ShowViewdialogDispatch(item)">
                                <v-icon  class="mdi-24px mdi-light">mdi-printer</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-dialog v-model="dialogPrintPdf"
                v-if="dialogPrintPdf"
                fullscreen
                transition="dialog-bottom-transition">
                    <dispatch-pdf @closedialogPrintPdf="closedialogPrintPdf()" :PrePackingPdf="SelectGuide"></dispatch-pdf>
            </v-dialog>
            <v-dialog 
                v-model="dialogDispatch"
                v-if="dialogDispatch"
                fullscreen
                transition="dialog-bottom-transition"
                persistent>
                <packing-dispatch :SelectPrePacking="SelectGuide" @closedialogDispatch="closedialogDispatch()"></packing-dispatch>
            </v-dialog> 
        </v-card-text>
    </v-card>
</template>

<script>
    import _sGuideDispatch from "@/services/FrozenProduction/FrzChamberPrePackingDispatch.js";
    import PackingDispatch from "./FrzChamberPrePackingDispatch.vue";
    import DispatchPdf from "./FrzChamberDispatchPdf.vue";
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    export default {
        components: {DispatchPdf, PackingDispatch, SSelectClient},
        data(){
            return{
                headersGuide: [
                    { text: "Editar", value: "Update"},
                    { text: "Imprimir", value: "Pdf"},
                    { text: "DESCRIPCION DEL PRODUCTO", value: "ProductDescription" },
                    { text: "Cliente", value: "CumName" },
                    { text: "N° Container", value: "NumberContainer" },
                    { text: "N° Cajas", value: "NumberBox" },
                    { text: "Peso Neto", value: "WeightNet" },
                    { text: "Corte", value: "FormatString" },
                    { text: "Presentacion", value: "Presentation" },
                    { text: "DESCRIPCION DEL PRODUCTO", value: "ProductDescription" },
                ],
                ItemsGuide: [],
                DateBegin: "",
                DateEnd: "",
                SelectGuide: {},
                dialogPrintPdf: false,
                dialogDispatch: false,
                filter: {}
            }
        },
        methods:{
            initialize(){
                _sGuideDispatch.listguide({DateBegin: this.DateBegin, DateEnd: this.DateEnd}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        this.ItemsGuide = r.data;
                        
                    }
                })
            },
            searchitemsSend(event)
            {
                this.initialize();
            },
            ShowViewdialogGruide(item){
                this.SelectGuide = item;
                
                this.dialogDispatch = true;
            },
            ShowViewdialogDispatch(item){
                this.SelectGuide = item;
                this.dialogPrintPdf = true;
            },
            closedialogPrintPdf(){
                this.dialogPrintPdf = false;
            },
            closedialogDispatch(){
                this.dialogDispatch = false;
            }
        },
        created(){
            this.initialize();
        }
    }
</script>